import { render, staticRenderFns } from "./GlobalActivitiesInscriptions.vue?vue&type=template&id=a77b8da6&scoped=true&"
import script from "./GlobalActivitiesInscriptions.vue?vue&type=script&lang=js&"
export * from "./GlobalActivitiesInscriptions.vue?vue&type=script&lang=js&"
import style0 from "./GlobalActivitiesInscriptions.vue?vue&type=style&index=0&id=a77b8da6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a77b8da6",
  null
  
)

export default component.exports