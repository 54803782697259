import moment from 'moment'
import { Activity, makeActivity } from '@/types/activities'
import { Place, makePlace } from '@/types/base'
import { Entity, makeEntity, Tag, makeTag, Individual, makeIndividual } from '@/types/people'
import { diffTimes } from '@/utils/time'

export class ActivityDate {
  constructor(
    public id: number,
    public activity: Activity,
    public startDateTime: Date,
    public endDateTime: Date,
    public place: Place,
    public label: string,
    public changeWarning: string,
    public dayComments: string,
    public conflict: boolean,
    public absence: boolean,
    public comments: string,
    public entity: Entity|null,
    public tag: Tag|null
  ) {
  }

  public timeframe(): string {
    const dateFormat = 'DD/MM/YYYY'
    const timeFormat = 'HH:mm'
    const start = moment(this.startDateTime).format('YYYY-MM-DD')
    const end = moment(this.startDateTime).format('YYYY-MM-DD')
    let hour1 = moment(this.startDateTime).format(timeFormat)
    let hour2 = moment(this.endDateTime).format(timeFormat)
    if (start === end) {
      let date = moment(this.startDateTime).format(dateFormat)
      date += ' ' + hour1 + ' - ' + hour2
      return date
    } else {
      let date1 = moment(this.startDateTime).format(dateFormat)
      let date2 = moment(this.startDateTime).format(dateFormat)
      return date1 + ' ' + hour1 + ' - ' + date2 + ' ' + hour2
    }
  }

  public getTimeRange(): string {
    const timeFormat = 'HH:mm'
    let hour1 = moment(this.startDateTime).format(timeFormat)
    let hour2 = moment(this.endDateTime).format(timeFormat)
    return hour1 + ' - ' + hour2
  }

  public hourframe(): string {
    const dateFormat = 'ddd D'
    const timeFormat = 'HH:mm'
    const start = moment(this.startDateTime).format('YYYY-MM-DD')
    const end = moment(this.startDateTime).format('YYYY-MM-DD')
    let hour1 = moment(this.startDateTime).format(timeFormat)
    let hour2 = moment(this.endDateTime).format(timeFormat)
    if (start === end) {
      return hour1 + ' - ' + hour2
    } else {
      let date1 = moment(this.startDateTime).format(dateFormat)
      let date2 = moment(this.startDateTime).format(dateFormat)
      return date1 + ' ' + hour1 + ' - ' + date2 + ' ' + hour2
    }
  }

  public startAt(): string {
    const timeFormat = 'HH:mm'
    return moment(this.startDateTime).format(timeFormat)
  }

  public endAt(): string {
    const timeFormat = 'HH:mm'
    return moment(this.endDateTime).format(timeFormat)
  }

  public match(otherDate: ActivityDate): boolean {
    if (otherDate.startDateTime >= this.startDateTime) {
      return otherDate.startDateTime < this.endDateTime
    } else {
      return this.startDateTime < otherDate.endDateTime
    }
  }

  public getLabel(): string {
    if (this.activity.id) {
      return this.activity.name
    }
    if (this.entity && this.entity.id) {
      return this.entity.name
    }
    if (this.tag && this.tag.id) {
      return this.tag.name
    }
    return this.label
  }

  public getDuration(): number {
    const start = moment(this.startDateTime).format('HH:mm')
    const end = moment(this.endDateTime).format('HH:mm')
    const duration = +diffTimes(end, start)
    return Math.round(duration / 36) / 100
  }

  public key(): string {
    const start = moment(this.startDateTime).format('YYYY-MM-DD HH:mm')
    const end = moment(this.endDateTime).format('YYYY-MM-DD HH:mm')
    return start + '/' + end
  }
}

export function makeActivityDate(jsonData: any = null): ActivityDate {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityDate(
    jsonData.id || 0,
    makeActivity(jsonData.activity),
    new Date(jsonData.start_datetime),
    new Date(jsonData.end_datetime),
    makePlace(jsonData.place),
    jsonData.label || '',
    jsonData.change_warning || '',
    jsonData.day_comments || '',
    !!jsonData.conflict,
    !!jsonData.absence,
    jsonData.comments || '',
    jsonData.entity ? makeEntity(jsonData.entity) : null,
    jsonData.tag ? makeTag(jsonData.tag) : null
  )
}

export class DateComment {
  constructor(
    public id: number,
    public date: Date,
    public comments: string
  ) {
  }
}

export function makeDateComment(jsonData: any = null): DateComment {
  if (!jsonData) {
    jsonData = {}
  }
  return new DateComment(
    jsonData.id || 0,
    new Date(jsonData.date),
    jsonData.comments || ''
  )
}

export enum AgendaPresence {
  Presence = 1,
  Missing = 2,
  Unknown = 3
}

export class AgendaDatePresence {
  constructor(
    public id: number,
    public individual: Individual,
    public date: ActivityDate,
    public presence: AgendaPresence,
    public comments: string
  ) {
  }

  public getKey(): string {
    return '' + this.individual.id + '#' + this.date.id
  }

  public getIcon(): string {
    if (this.presence === AgendaPresence.Presence) {
      return 'fa fa-circle-check'
    } else if (this.presence === AgendaPresence.Missing) {
      return 'fa fa-circle-exclamation'
    } else if (this.presence === AgendaPresence.Unknown) {
      return 'fa fa-circle-question'
    } else {
      return 'fa fa-exclamation'
    }
  }

  public getPdfIcon(): string {
    if (this.presence === AgendaPresence.Presence) {
      return 'x'
    } else if (this.presence === AgendaPresence.Missing) {
      return 'Abs'
    } else if (this.presence === AgendaPresence.Unknown) {
      return '?'
    } else {
      return ''
    }
  }
}

export function makeAgendaDatePresence(jsonData: any = null): AgendaDatePresence {
  if (!jsonData) {
    jsonData = {}
  }
  return new AgendaDatePresence(
    jsonData.id || 0,
    makeIndividual(jsonData.individual),
    makeActivityDate(jsonData.date),
    jsonData.presence,
    jsonData.comments || ''
  )
}
