<template>
  <div>
    <div>
      <b-row class="hide-here">
        <b-col cols="6">
          <b>{{ structureName }}</b>
        </b-col>
        <b-col l cols="6" class="text-right">
          {{ currentTime }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="members-col" cols="9">
          <b>{{ globalTitle }}</b>
        </b-col>
        <b-col cols="3">
          <table v-if="showFields && showLegend" class="table text-left small-table">
            <tr class="no-print"><th>Légende</th></tr>
            <tr v-for="field of fields" :key="field.id">
              <td v-if="field.fullText !== field.text">
                {{ field.text}}: {{ field.fullText }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <div v-if="inscriptions.length === 0">Aucune inscription pour l'instant</div>
      <div v-for="elt of scopes" :key="elt.id">
        <div v-if="elt.inscriptions.length">
          <div class="grouper-header">{{ elt.title }} ({{ elt.inscriptions.length }})</div>
          <table class="table table-grid text-center">
            <tr v-if="seances.length">
              <th class="text-left">Date</th>
              <th v-if="showAge">&nbsp;</th>
              <th v-if="showBirth">&nbsp;</th>
              <th v-if="showFields">&nbsp;</th>
              <th v-if="showPhone">&nbsp;</th>
              <th v-if="showEmail">&nbsp;</th>
              <td
                v-for="seance of seances"
                :key="seance"
                :style="seanceDateStyle"
              >
                <div
                  :class="vertCol ? 'rotate-me small-dt' : 'small-text'"
                  :style="vertWidth"
                >
                  {{ getSeanceDate(seance) }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="text-left">Nom</th>
              <th v-if="showAge" style="min-width: 100px;">Age</th>
              <th v-if="showBirth" style="min-width: 100px;">Né(e) le</th>
              <th v-if="showFields" style="min-width: 100px;">Infos</th>
              <th v-if="showPhone">Téléphone</th>
              <th v-if="showEmail">Email</th>
              <th v-for="seance of seances" :key="seance" :style="seanceStyle">{{ seance }}</th>
            </tr>
            <tr v-for="inscription of elt.inscriptions" :key="inscription.id" class="inscriptions-list">
              <td class="text-left">
                <router-link :to="getEntityLink(inscription)">
                  {{ inscription.individual.firstAndLastName() }}
                </router-link>
                <span
                  v-if="inscription.trying"
                  class="badge"
                  :class="inscription.isTryingFinished() ? 'badge-danger' : 'badge-dark'"
                >
                  <i class="fa fa-warning" v-if="inscription.isTryingFinished()"></i>
                  A l'essai
                  <span v-if="inscription.tryingDate">
                    jusqu'au {{ inscription.tryingDate | dateToString }}
                  </span>
                </span>
                <span v-if="inscription.waiting" class="badge badge-dark">En attente</span>
                <span v-if="inscription.hasLeftOn" class="badge badge-danger">
                  a quitté le {{ inscription.hasLeftOn | dateToString }}
                </span>
              </td>
              <td v-if="showAge" class="small-text-col">{{ inscription.individual.getAge() }}</td>
              <td v-if="showBirth" class="small-text-col">{{ inscription.individual.birthDate | dateToString }}</td>
              <td v-if="showFields" class="small-text-col">
                <div v-for="field of fields" :key="field.id">
                  <a
                    href
                    @click.prevent="onEditField(inscription, field)"
                    v-if="isFieldVisible(inscription, field)"
                  >
                    {{ field.text}}: {{ inscription.getFieldText(field) | defaultValue('-') }}
                  </a>
                </div>
              </td>
              <td v-if="showPhone" class="small-text-col">
                <phone-link :phone="inscription.getCellPhoneOrProPhone()"></phone-link>
              </td>
              <td v-if="showEmail" class="small-text-col">
                <email-link :email="inscription.getEmail()" default-value="-"></email-link>
              </td>
              <td v-for="seance of seances" :key="seance" :style="seanceStyle">
                <span>&nbsp;</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <individual-field-modal
      :individual="selectedIndividual"
      :field="selectedField"
      :read-only="!(selectedField && selectedField.canEdit)"
      :id="0"
      @updated="onIndividualFieldUpdated()"
    >
    </individual-field-modal>
  </div>
</template>

<script>
import EmailLink from '@/components/Controls/Links/EmailLink'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import IndividualFieldModal from '@/components/Fields/IndividualFieldModal'
import { getHostName } from '@/utils/http'
import store from '@/store'
import moment from 'moment/moment'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { compareDates } from '@/utils/sorting'
import { dateToString } from '@/filters/texts'

export default {
  name: 'GlobalActivitiesInscriptions',
  mixins: [BackendMixin],
  props: {
    showAge: Boolean,
    showBirth: Boolean,
    showWaiting: Boolean,
    showFields: Boolean,
    showLegend: Boolean,
    showPhone: Boolean,
    showEmail: Boolean,
    showTryingLimits: Boolean,
    seancesCount: {
      type: [String, Number],
      default: Number,
    },
    fromDate: String,
    weekly: Boolean,
    inscriptions: Array,
    dates: Array,
  },
  components: {
    EmailLink,
    PhoneLink,
    IndividualFieldModal,
  },
  data() {
    return {
      currentTimeFormat: 'llll',
      currentTime: '',
      loadingName: 'activity-seances',
      fields: [],
      selectedIndividual: null,
      selectedField: null,
      presenceMap: new Map(),
      agendaPresence: null,
    }
  },
  watch: {
    showTryingLimits: function() {},
    fromDate: function() {},
  },
  computed: {
    globalTitle() {
      if (this.$route.query && this.$route.query.title) {
        return this.$route.query.title
      } else {
        return ''
      }
    },
    vertCol() {
      return (this.seancesCount > 12) && (this.dates.length > 12)
    },
    vertWidth() {
      if (this.vertCol) {
        let value = 70
        if (this.seancesCount > 20) {
          value = 40
        }
        if (this.seancesCount > 30) {
          value = 20
        }
        return {
          width: '' + value + 'px',
        }
      }
      return {}
    },
    structureName() {
      return store.getters.structureName
    },
    isYouthHome() {
      return +this.youthHome
    },
    seanceStyle() {
      let style = 'border: solid 1px #ccc; text-align: center;'
      if (this.seancesCount) {
        style += 'width: ' + (80 / this.seancesCount) + '%;'
      } else {
        style += 'width: 70%;'
      }
      return style
    },
    seanceDateStyle() {
      let style = this.seanceStyle
      if (this.vertCol) {
        if (this.seancesCount > 20) {
          style += 'height: 70px;'
        } else {
          style += 'height: 90px;'
        }
      }
      return style
    },
    backendName() {
      return getHostName(store.getters.backendName)
    },
    waitingInscriptions() {
      return this.inscriptions.filter(
        elt => elt.waiting && !elt.trying
      )
    },
    tryingInscriptions() {
      let inscriptions = this.inscriptions.filter(
        elt => !elt.waiting && elt.trying
      )
      if (!this.showTryingLimits) {
        inscriptions = inscriptions.filter(
          elt => !elt.isTryingFinished()
        )
      }
      return inscriptions
    },
    scopes() {
      let scopes = [
        { id: 1, title: 'Inscriptions définitives', inscriptions: this.inscriptions, },
        { id: 2, title: 'Inscriptions à l\'essai', inscriptions: this.tryingInscriptions, }
      ]
      if (this.showWaiting) {
        scopes.push(
          { id: 3, title: 'Inscriptions en attente', inscriptions: this.waitingInscriptions, },
          { id: 4, title: 'Inscriptions en attente et à l\'essai', inscriptions: this.waitingButTryingInscriptions, }
        )
      }
      return scopes
    },
    waitingButTryingInscriptions() {
      let inscriptions = this.inscriptions.filter(
        elt => elt.waiting && elt.trying
      )
      if (!this.showTryingLimits) {
        inscriptions = inscriptions.filter(
          elt => !elt.isTryingFinished()
        )
      }
      return inscriptions
    },
    seances() {
      return Array.from({ length: this.seancesCount, }, (value, index) => index + 1)
    },
    editMode() {
      return (store.getters.editMode !== '')
    },
    selectorDisabled() {
      return (store.getters.editMode !== '')
    },
  },
  created() {
    this.currentTime = moment().format(this.currentTimeFormat)
  },
  methods: {
    ...mapActions(['addError']),
    getEntityLink(inscription) {
      return {
        name: 'families-detail',
        params: { entityId: '' + inscription.entity.id, },
        query: { individual: inscription.individual.id, },
      }
    },
    onEditField(inscription, field) {
      this.selectedField = field
      this.selectedIndividual = inscription.individual
      this.$bvModal.show('bv-individual-field-modal' + '0')
    },
    isFieldVisible(inscription, field) {
      if (inscription.isFieldValueVisible(field)) {
        if (field.canEdit) {
          return true
        } else {
          return inscription.getFieldText(field)
        }
      }
      return false
    },
    getDateObj(seance) {
      if (this.dates.length) {
        let dates = this.dates
        if (this.fromDate) {
          dates = this.dates.filter(
            elt => {
              let value = compareDates(elt.startDateTime, moment(this.fromDate).toDate())
              return value >= 0
            }
          )
        }
        let index = seance - 1
        if (index < dates.length) {
          return dates[index]
        }
      }
      return null
    },
    getSeanceDate(seance) {
      const dateObj = this.getDateObj(seance)
      if (dateObj) {
        let text = dateToString(dateObj.startDateTime, 'DD/MM/YYYY')
        if (this.weekly) {
          text = 'Semaine ' + text
        }
        return text
      }
      return ''
    },
    onAgendaPresenceClicked(event) {
      this.agendaPresence = event
      const that = this
      this.$nextTick(
        () => {
          that.$bvModal.show('set-agenda-presence-modal' + that.activity.id)
        }
      )
    },
    onPresenceSaved(presence) {
      this.presenceMap.set(presence.getKey(), presence)
      this.presenceMap = new Map(this.presenceMap)
    },
  },
}
</script>

<style scoped>
.rotate-me {
  transform-origin: top center;
  transform: rotate(90deg) translate(50%);
  margin-right: -60px;
  padding-right: -60px;
}
.small-dt {
  font-size: 10px;
}
</style>
