<template>
  <div class="individual-field-modal">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      @ok="onSave"
      ok-variant="primary"
      :ok-title="readOnly ? 'Fermer' : 'Enregistrer'"
      :ok-only="readOnly"
    >
      <template v-slot:modal-title>
        <span v-if="individual">
          {{ individual.fullName() }}
        </span>
      </template>
      <fields-input
        :fields-groups="fieldsGroups"
        :individual="individual"
        :field-values="fieldValues"
        :read-only="readOnly"
        @change="onFieldChanged($event)"
      >
      </fields-input>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FieldsInput from '@/components/Fields/FieldsInput'
import { BackendMixin } from '@/mixins/backend'
import { makeFieldsGroup, makeFieldValues, FieldType } from '@/types/fields'
import { BackendApi } from '@/utils/http'

export default {
  name: 'individual-field-modal',
  components: {
    FieldsInput,
  },
  mixins: [BackendMixin],
  props: {
    field: Object,
    individual: Object,
    readOnly: Boolean,
    id: [String, Number],
  },
  data() {
    return {
      value: null,
      fieldsGroups: [],
      fieldValues: makeFieldValues(),
      fieldsData: {},
      commentsData: {},
    }
  },
  computed: {
    modalId() {
      return 'bv-individual-field-modal' + this.id
    },
    loadingName() {
      return 'individual-field-modal'
    },
    currentValues() {
      return []
    },
  },
  watch: {
    field: function() { this.init() },
    individual: function() { this.init() },
    readOnly: function() {},
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    init() {
      this.loadData()
      this.loadValues()
    },
    async loadData() {
      if (this.field && this.field.field && this.hasPerm('fields.view_fieldsgroup')) {
        this.startLoading(this.loadingName)
        try {
          const url = '/api/fields/groups/?field_id=' + this.field.field
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.fieldsGroups = resp.data.map(elt => makeFieldsGroup(elt))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadValues() {
      if (this.individual) {
        const url = '/api/people/individual-values/' + this.individual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.fieldValues = makeFieldValues(resp.data)
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    onFieldChanged(event) {
      this.fieldsData = event.fieldsData
      this.commentsData = event.commentsData
    },
    async onSave() {
      if (this.readOnly) {
        return
      }
      let postData = []
      for (const fieldsGroup of this.fieldsGroups) {
        for (let field of fieldsGroup.fields) {
          let textValue = ''
          const isText = (
            (field.fieldType === FieldType.Text) ||
            (field.fieldType === FieldType.Integer) ||
            (field.fieldType === FieldType.Decimal) ||
            (field.fieldType === FieldType.Date)
          )
          if (isText) {
            textValue = '' + (this.fieldsData[field.id] || '')
          }
          postData.push(
            {
              field: field.id,
              text_value: textValue,
              choice_value: (field.fieldType === FieldType.Choices) ? this.fieldsData[field.id] || null : null,
              boolean_value: (field.fieldType === FieldType.Boolean) ? (!!this.fieldsData[field.id]) : null,
              comments: this.commentsData[field.id] || '',
            }
          )
        }
      }
      const url = '/api/people/individual-values/' + this.individual.id + '/'
      const backendApi = new BackendApi('post', url)
      try {
        let resp = await backendApi.callApi(postData)
        this.fieldValues = makeFieldValues(resp.data)
        this.$emit('updated')
        await this.addSuccess('Les données ont été enregistrées')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">

</style>
